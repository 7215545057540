import { useState, useCallback, useEffect } from 'react';
import { usePopupWindow, usePopupWindowSubscription, useOpenState } from '@flame-frontend-utils/commons';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { getAuthForm } from './getAuthForm';
import { ButtonGroup } from '../ButtonGroup';
import { SidebarHeader } from '../SidebarHeader';
import { AuthType } from './types';
import { AuthTypeItems } from './constants';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';
import { ignoreReject } from '../../lib/ignoreReject';

interface Props {
  onClose: () => void;
}

const SUCCESS_MESSAGE = 'success';

const Authentication = ({ onClose }: Props): JSX.Element => {
  const { search } = useLocation();
  const [isOpen] = useOpenState();
  const auth = new URLSearchParams(search).get('auth');

  const [authType, setAuthType] = useState<AuthType>(AuthType.Login);

  const { openPopup, closePopup, popupRef } = usePopupWindow();

  const { refetch: refetchLoginInfo } = useLoginInfo();

  const onAuthTypeClick = (value: AuthType, popupUrl?: string) => {
    if (popupUrl) {
      openPopup(popupUrl);
    } else {
      setAuthType(value);
    }
  };

  const onPopupMessage = useCallback(
    async (message: unknown) => {
      if (message === SUCCESS_MESSAGE) {
        await ignoreReject(refetchLoginInfo());
        onClose();
      } else {
        toast.error('Что-то пошло не так. Попробуйте еще раз.');
      }

      closePopup();
    },
    [closePopup, onClose, refetchLoginInfo]
  );

  useEffect(() => {
    if (auth === 'signup') {
      setAuthType(AuthType.Registration);
    }
  }, [isOpen, auth]);

  usePopupWindowSubscription({ popupRef, onMessage: onPopupMessage });

  const AuthForm = getAuthForm(authType);

  return (
    <div className="self-start">
      <SidebarHeader title="Вход" onClose={onClose} />
      <ButtonGroup
        className="mx-0 mb-16 mt-0"
        buttons={AuthTypeItems}
        selectedValue={authType}
        onClick={onAuthTypeClick}
      />
      {AuthForm && <AuthForm onClose={onClose} setAuthType={setAuthType} />}
    </div>
  );
};

export default Authentication;
export type { Props };
